<script>
  import QuestionAccordion from '@/components/QuestionAccordion.svelte'
  import Question from '@/components/Question.svelte'

  import { CheckmarkFilled, CloseRound } from '@/components/icons'

  export let darkTheme = false

  const differences = [
    {
      text: 'Translate your check engine light into plain English',
      free: true,
      premium: true,
    },
    {
      text: 'View problem severity',
      free: true,
      premium: true,
    },
    {
      text: 'Get maintenance alerts',
      free: true,
      premium: true,
    },
    {
      text: "Monitor multiple vehicles' health from one phone",
      free: true,
      premium: true,
    },
    {
      text: 'See what repairs are needed and how much they should cost',
      free: false,
      premium: true,
    },
    {
      text: 'Call a FIXD Mechanic for FREE advice',
      free: false,
      premium: true,
    },
    {
      text: 'Predict likely future car problems',
      free: false,
      premium: true,
    },
    {
      text: 'View vehicle ownership and accident history',
      free: false,
      premium: true,
    },
    {
      text: 'Precheck emissions at home',
      free: false,
      premium: true,
    },
  ]
</script>

<div class:bg-black={darkTheme} class="pl-2 pr-2 md:pl-12 md:pr-5">
  <QuestionAccordion>
    <Question
      title="What is FIXD Premium?"
      titleSize="base"
      titleWeightClass="font-bold"
      {darkTheme}
      additionTitleClass="text-lg md:text-base"
    >
      <p class="text-sm">
        FIXD Premium is the expanded version of our free FIXD app that delivers
        accurate cost estimates for your specific repairs, gives you access to
        on-call FIXD Mechanics and DIY assistance, and helps you handle any car
        issue with confidence.
      </p>
    </Question>
    <Question
      title="How does FIXD Premium work?"
      titleSize="base"
      titleWeightClass="font-bold"
      {darkTheme}
      additionTitleClass="text-lg md:text-base"
    >
      <p class="text-sm">
        FIXD Premium connects to your FIXD sensor through the FIXD app
        (available for Android and iOS). It automatically syncs your phone to
        your vehicle using Bluetooth technology.
      </p>
    </Question>
    <Question
      title="How is FIXD Premium different from the free app?"
      titleSize="base"
      titleWeightClass="font-bold"
      {darkTheme}
      additionTitleClass="text-lg md:text-base"
    >
      <div>
        <p class="text-sm">
          The free FIXD app tells you what’s wrong with your car and includes
          maintenance reminders. FIXD Premium offers advanced features anpGreed
          on-call support from experts to give you complete control over your
          vehicle maintenance and repairs.
        </p>

        <div class="space-y-5 mt-10 md:mt-0 md:p-5">
          <div class="flex md:gap-5">
            <div
              class="basis-8/12 md:basis-1/3 rounded-t-3xl bg-black py-4 md:py-2"
            >
              <p class="text-white text-xs md:text-lg font-bold text-center">
                FIXD Features
              </p>
            </div>
            <div
              class="basis-2/12 md:basis-1/3 rounded-t-3xl bg-[#ebebeb] py-4 md:py-2"
            >
              <p class="text-xs md:text-lg font-bold text-center">Free App</p>
            </div>
            <div
              class="basis-2/12 md:basis-1/3 rounded-t-3xl bg-green py-4 md:py-2"
            >
              <p class="text-white text-xs md:text-lg font-bold text-center">
                Premium
              </p>
            </div>
          </div>
          {#each differences as difference}
            <div class="flex md:gap-5 items-center">
              <div class="basis-8/12 md:basis-1/3">
                <p class="text-sm text-center">{difference.text}</p>
              </div>
              <div class="basis-2/12 md:basis-1/3 text-center">
                {#if difference.free}
                  <CheckmarkFilled color="green" size="lg" />
                {:else}
                  <div
                    class="bg-[#dbdbdb] rounded-full inline-flex w-6 h-6 items-center justify-center"
                  >
                    <CloseRound color="white" size="xs" />
                  </div>
                {/if}
              </div>
              <div class="basis-2/12 md:basis-1/3 text-center">
                {#if difference.premium}
                  <CheckmarkFilled color="green" size="lg" />
                {:else}
                  <div
                    class="bg-[#dbdbdb] rounded-full flex w-6 h-6 items-center justify-center"
                  >
                    <CloseRound color="white" size="xs" />
                  </div>
                {/if}
              </div>
            </div>
          {/each}
        </div>
      </div>
    </Question>

    <Question
      title="What’s included in my FIXD Premium membership?"
      titleSize="base"
      titleWeightClass="font-bold"
      {darkTheme}
      additionTitleClass="text-lg md:text-base"
    >
      <div>
        <p class="text-sm mb-4">
          Your FIXD Premium membership includes access to exclusive features
          within the FIXD app, including:
        </p>

        <ul class="list-disc pl-10">
          <li class="text-sm">
            FIXD Mechanic Hotline: Call anytime for second opinions, at-home
            repairs, and more
          </li>
          <li class="text-sm">
            Confirmed Fix & Cost: Get detailed engine diagnostics + cost
            estimates for your repairs
          </li>
          <li class="text-sm">
            Issue Forecast: Predict future problems and avoid costly repairs
            down the road
          </li>
          <li class="text-sm">
            Issue Commonality: Know how common or rare your issue is
          </li>
          <li class="text-sm">
            Emissions Pre-Check: Make sure you'll pass the first time with this
            easy at-home test.
          </li>
        </ul>

        <p class="text-sm mt-2.5">
          Plus, you’ll enjoy VIP customer service and be the first to know about
          new updates and features and special offers!
        </p>
      </div>
    </Question>

    <Question
      title="Do I have to buy FIXD Premium to scan my vehicle and get diagnostic info on my phone?"
      titleSize="base"
      titleWeightClass="font-bold"
      {darkTheme}
      additionTitleClass="text-lg md:text-base"
    >
      <p class="text-sm">
        No. FIXD Premium is a 100% optional upgrade. You can still diagnose
        +7000 potential car problems in plain English using your FIXD sensor and
        the free FIXD app on your phone. There are also other features, such as
        maintenance tracking, wear items, history log, and issue severity that
        come for free with FIXD. However, if you like the idea of becoming your
        own car guy (or gal) and staying even more in the know about your
        vehicle, a FIXD Premium membership is the smart choice.
      </p>
    </Question>

    <Question
      title="How often can I call the FIXD Mechanic Hotline?"
      titleSize="base"
      titleWeightClass="font-bold"
      {darkTheme}
      additionTitleClass="text-lg md:text-base"
    >
      <p class="text-sm">
        As often as you'd like! Our FIXD Mechanics are available to you Monday -
        Friday 8am - 8pm for second opinions, car maintenance questions, and DIY
        repairs. If you have an after-hours emergency, leave a message and
        they'll get back to you within 24 hours!
      </p>
    </Question>

    <Question
      title="Will I be charged extra for the time I’m on the phone with a FIXD Mechanic?"
      titleSize="base"
      titleWeightClass="font-bold"
      {darkTheme}
      additionTitleClass="text-lg md:text-base"
    >
      <p class="text-sm">
        Nope! FIXD Premium members can speak with our in-house mechanics at no
        extra cost. Get unbiased, expert second opinions and all your car
        questions answered for free.
      </p>
    </Question>

    <Question
      title="What if I try FIXD Premium and don’t like it?"
      titleSize="base"
      titleWeightClass="font-bold"
      {darkTheme}
      additionTitleClass="text-lg md:text-base"
    >
      <p class="text-sm">
        We know you're going to be thrilled with how FIXD Premium saves you
        time, money, and stress on car care. If you're not happy for any reason,
        though, we've made it
        <a
          href="https://staff.fixdapp.com/products/subscriptions/cancel"
          target="_blank"
          rel="noreferrer"
          tabindex="-1"
          class="text-green font-bold"
        >
          simple to cancel.</a
        >
      </p>
    </Question>
  </QuestionAccordion>
</div>
