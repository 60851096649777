<script>
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import HeroBackgroundImage from '@/images/landing-page/scorpio/holiday-hero-image.jpg'
  import HeroGif from '@/images/landing-page/scorpio/holiday-hero.gif'
  import RepairCarIcon from '@/images/landing-page/scorpio/holiday-car-repair-icon.png'
  import LiveMechanicIcon from '@/images/landing-page/scorpio/holiday-live-mechanic-icon.png'
  import AiMechanicIcon from '@/images/landing-page/scorpio/holiday-ai-mechanic-icon.png'

  import TalkToMechanicImage from '@/images/landing-page/mechanic-hotline-2.png'
  import KnowVehicleHistoryImage from '@/images/landing-page/maintenance-repairs1.png'
  import CheckMarkImage from '@/images/landing-page/check1.png'
  import PredictCarProblemsImage from '@/images/landing-page/CEL-duo2.png'
  import KnowYourRepairImage from '@/images/landing-page/scorpio/holiday-know-cost.png'
  import ConsultAiMechanicImage from '@/images/landing-page/scorpio/holiday-ai-mechanic.png'
  import ShieldIcon from '@/images/shield-icon-green.png'

  import KeysIcon from '@/images/landing-page/scorpio/premium_50_keys-icon-new.png'
  import GraphIcon from '@/images/landing-page/scorpio/premium_50_graph-icon.png'
  import UserIcon from '@/images/landing-page/scorpio/premium_50_user-icon.png'
  import SaveMoneyIcon from '@/images/landing-page/scorpio/premium_50_save-money-icon.png'
  import EmissionIcon from '@/images/landing-page/scorpio/premium_50_emissions-icon.png'

  import Sarah from '@/images/review-avatars/sarah2.png'
  import Travis from '@/images/review-avatars/travis.png'
  import Unknown from '@/images/review-avatars/unknown.png'
  import Pete from '@/images/review-avatars/pete.png'

  import FAQ from './components/Premium50Faq.svelte'

  import PremiumLogo from '@/images/landing-page/scorpio/holiday-premium-logo.png'

  import {
    FaStarFilledRound,
    Logo,
    FixdLogoText,
    ShoppingCartSolid,
  } from '@/components/icons'

  const linkToCheckout = () => {
    window.location.href = '/buy/premium-50'
  }

  const featureInPremium = [
    {
      img: TalkToMechanicImage,
      alt: 'talk to a mechanic',
      title: 'Talk to a mechanic',
      description:
        'From trusted second opinions to questions about what parts to buy to DIY repair walkthroughs, our FIXD Mechanics are here for you M-F 8am to 8pm EST.',
    },
    {
      img: KnowVehicleHistoryImage,
      alt: 'Know vehicle history',
      title: 'Know vehicle history',
      description:
        'Buying a used car? Find out previous owner information, accident history, & details of any car before you buy.',
    },
    {
      img: KnowYourRepairImage,
      alt: 'Know your repair cost',
      title: 'Know your repair cost',
      description:
        "With FIXD Premium, you'll not only know what your engine code means, but you'll also get fair market prices for your specific repairs to avoid rip-offs.",
    },
    {
      img: CheckMarkImage,
      alt: 'Pass emissions the first time',
      title: 'Pass emissions the first time',
      description:
        'Save money and avoid a wasted trip to a crowded testing station by checking your emissions at home before getting the test.',
    },
    {
      img: PredictCarProblemsImage,
      alt: 'Predict future car problems',
      title: 'Predict future car problems',
      description:
        'Identify common problems based on data from other people with your car, helping you extend the life of your vehicle and avoid costly repairs down the road.',
    },
    {
      img: ConsultAiMechanicImage,
      alt: 'Consult the AI Mechanic 24/7',
      title: 'Consult the AI Mechanic 24/7',
      description:
        'Get expert answers, tutorials tailored to you, and much more - any time you need it.',
    },
  ]

  const reviews = [
    {
      img: Sarah,
      description:
        "The main use is the cost estimates for everything. It's powerful to be able to hold that up to the mechanic and be like 'wait a second, that estimate is way over.'",
      name: 'Sarah B.',
    },
    {
      img: Pete,
      description:
        'Love FIXD Premium. Went to a mechanic and [he told me] I needed much more done to my Hyundai and I told him to fix only what the app said to repair.  He then asked how I know, I showed him the FIXD recommended repair and cost. Never went back.',
      name: 'Travis W.',
    },
    {
      img: Unknown,
      description:
        "I called the mechanic hotline and believe you me, my money... was well spent. I spoke to John and he was a gentleman. Didn't talk down to me... answered my questions and told me to never hesitate to call for any reason about the car. Thank you so much for the ability to call a mechanic when I need help. I appreciate it and believe you me I will tell everyone what a class A company FIXD is.",
      name: 'Gretchen M.',
    },
    {
      img: Travis,
      description:
        'FIXD Premium is great. My car started making a strange noise.. so, a neighbor who calls himself a mechanic told me it was nothing. Then I checked on FIXD Premium... it told me exactly what I needed to have done (immediately). I showed my mechanic, and he went right to work and it only took a short time and I was back on the road!',
      name: 'Pete W.',
    },
  ]

  const premiumFeatures = [
    '✔️ 50% Off 1 year of FIXD Premium - only $50 for 12 months of Premium features',
    '✔️ FIXD Mechanic Hotline: Get expert support from our on-call mechanics, 5 days a week',
    '✔️ Confirmed Fix & Cost: See how to fix your issue and how much it should cost',
    '✔️ Issue Forecast: Know what issues your car is most likely to face in the future',
    '✔️ Vehicle History Report: Get accident history, previous owner information, and more on any vehicle',
    "✔️ Emissions Precheck: Be confident you'll pass emissions the first time",
  ]

  const featureItems = [
    {
      img: UserIcon,
      title: 'Talk To A Mechanic',
      description:
        'From trusted second opinions to questions about what parts to buy to DIY repair walkthroughs, our FIXD Mechanics are here for you 6 days a week.',
    },
    {
      img: SaveMoneyIcon,
      title: 'Know Your Repair Cost',
      description:
        'With FIXD Premium, you’ll not only know what your engine code means, but you’ll also get fair market prices for your specific repairs to avoid rip-offs.',
    },
    {
      img: GraphIcon,
      title: 'Predict Future Car Problems',
      description:
        'Identify common problems based on data from other people with your car, helping you extend the life of your vehicle and avoid costly repairs down the road.',
    },
    {
      img: KeysIcon,
      title: 'Know Vehicle History',
      description:
        'Buying a used car? Find out previous owner information, accident history, & details of any car before you buy.',
    },
    {
      img: EmissionIcon,
      title: 'Pass Emissions On The First Time',
      description:
        'Save money and avoid a wasted trip to a crowded testing station by checking your emissions at home before getting the test.',
    },
  ]
</script>

<style>
  .text-shadow {
    text-shadow: 2px 2px 10px #3c3c43;
  }

  .text-shadow-dark {
    text-shadow: 2px 2px 3px #000;
  }

  .parallax-hero {
    background-position: 50%, 100%, 0, 0;
  }

  @media only screen and (width >= 480px) {
    .parallax-hero {
      background-position: 0, 0, 0, 0;
    }

    .text-shadow-dark {
      text-shadow: 2px 2px 10px #3c3c43;
    }
  }
</style>

<main class="text-black font-montserrat">
  <Section verticalPadding="none">
    <div class="flex justify-between py-3">
      <div class="flex items-center gap-2">
        <Logo color={'green'} size={'sm'} />
        <FixdLogoText color={'#3F4147'} size={'sm'} />
      </div>
      <div
        role="button"
        tabindex="-1"
        class="border-[3px] border-green rounded-lg flex items-center px-4 py-2 gap-1"
        on:keypress={linkToCheckout}
        on:click={linkToCheckout}
      >
        <p class="text-green font-bold text-sm">Claim Offer</p>
        <ShoppingCartSolid size="sm" color="green" />
      </div>
    </div>
  </Section>

  <div
    class="bg-cover py-10 text-center parallax-hero"
    style="background-image: url({HeroBackgroundImage});
      background-attachment: fixed, scroll;
      
    "
  >
    <div class="text-white mt-5 mb-2.5">
      <h1 class="text-38 text-shadow">Get FIXD Premium</h1>
      <h1 class="text-38 text-shadow-dark text-green xs:text-white">
        50% OFF!
      </h1>
    </div>
    <div class="mb-2.5">
      <img
        src={HeroGif}
        alt="hero-gif"
        class="w-[72%] xs:w-[20%] xl:w-[10%] md:min-w-[235px]"
      />
    </div>
    <div class="mx-auto w-3/4 md:w-[45%]">
      <button class="rounded-button" on:click={linkToCheckout}>
        <span class="text-sm uppercase">Claim this deal</span></button
      >
    </div>
  </div>

  <div class="bg-[#f2f2f2] md:py-[62px]">
    <Section center={true}>
      <div class="mb-3 md:mb-9">
        <h2 class="text-30 font-extrabold md:font-semibold">
          Why upgrade to FIXD Premium?
        </h2>
      </div>
      <div class="flex flex-col md:flex-row text-center md:text-left">
        <div class="md:basis-1/2 md:pt-10 md:pr-12">
          <p class="text-22 md:text-28 mb-2.5 font-medium">
            You care about your car.
          </p>
          <div class="space-y-4 text-sm md:text-base">
            <p>
              FIXD Premium helps you care <span class="italic font-bold"
                >for</span
              > it, too.
            </p>
            <p>
              The FIXD Premium app brings total transparency to car repair and
              maintenance, making it easy to save money and stay on the road
              longer.
            </p>
          </div>
          <button class="rounded-button mt-2.5" on:click={linkToCheckout}>
            <span class="text-white font-bold text-lg uppercase"
              >claim this deal</span
            >
          </button>
        </div>
        <div class="md:basis-1/2 mt-12 md:mt-0 space-y-6 pl-2 md:pl-6 pr-2">
          <div class="md:px-5">
            <div class="flex flex-col md:flex-row items-center gap-2">
              <img
                src={RepairCarIcon}
                alt="Guaranteed repair estimates"
                class="w-20 md:w-[55px]"
              />
              <p class="text-lg font-bold">Guaranteed repair estimates</p>
            </div>

            <p>
              Know potential repair costs before you go with in-app RepairPal
              Certified™ estimates.
            </p>
          </div>
          <div class="md:px-5">
            <div class="flex flex-col md:flex-row items-center gap-2">
              <img
                src={LiveMechanicIcon}
                alt="Live mechanic help line"
                class="w-20 md:w-[55px]"
              />
              <p class="text-lg font-bold">Live mechanic help line</p>
            </div>
            <p>
              Seasoned mechanics are on-call <span
                class="font-bold block md:inline-block">5 days a week</span
              > to answer questions, review shop estimates, and walk you through
              DIY repairs.
            </p>
          </div>
          <div class="md:px-5">
            <div class="flex flex-col md:flex-row items-center gap-2">
              <img
                src={AiMechanicIcon}
                alt="Guaranteed repair estimates"
                class="w-20 md:w-[55px]"
              />
              <p class="text-lg font-bold">24/7 AI Mechanic</p>
            </div>

            <p>
              Troubleshoot, get tutorials, ask questions about your car - even
              at 3AM!
            </p>
          </div>
        </div>
      </div>
    </Section>
  </div>

  <div class="md:bg-[#e2e2e2] pt-0 md:pt-16 pb-20">
    <div class="max-w-[940px] mx-auto">
      <div class="mb-6 md:pb-5">
        <div class="relative pb-[56.25%]">
          <iframe
            class="absolute top-0 left-0 w-full h-full"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/76O_4tVWIqI?si=rdwb7nlW2x0gCA3s"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowfullscreen
          />
        </div>
      </div>
      <div class="text-center">
        <div class="flex md:hidden justify-center">
          {#each new Array(5) as _}
            <FaStarFilledRound color="yellow" size="md" />
          {/each}
        </div>
        <div class="hidden md:flex justify-center">
          {#each new Array(5) as _}
            <FaStarFilledRound color="yellow" size="lg" />
          {/each}
        </div>
        <div class="px-6 md:px-16 mt-2.5">
          <p class="text-lg leading-tight">
            “My family has saved thousands of dollars in labor alone. Thank you!
            Over 6 different vehicles have been fixed using FIXD Premium.”
          </p>
          <p class="font-bold text-sm">- Jennifer G.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="md:mt-14 mt-5 mb-7 md:mb-20 max-w-[1280px] mx-auto">
    <div class="max-w-[1280px] mx-auto">
      <div class="flex flex-col md:flex-row justify-center items-center">
        <h2 class="font-extrabold md:font-semibold text-28 md:text-4xl">
          Features Included In
        </h2>
        <div class="flex items-center">
          <img src={ShieldIcon} class="w-9 md:w-12" alt="shield icon" />
          <h2 class="text-28 md:text-4xl text-green font-semibold">
            FIXD Premium
          </h2>
        </div>
      </div>

      <div class="md:hidden mx-3">
        <Carousel showArrows={true}>
          {#each featureItems as item}
            <CarouselItem>
              <div class="text-center px-10 mt-5 mb-10">
                <img src={item.img} alt={item.title} class="mb-4 w-2/12" />
                <p class="text-lg font-bold">{item.title}</p>
                <p class="text-15 mt-2.5">{item.description}</p>
              </div>
            </CarouselItem>
          {/each}
        </Carousel>
      </div>

      <div class="mt-10 hidden md:block">
        <div class="columns-3 gap-10">
          {#each featureInPremium as item}
            <div class="flex py-8">
              <div class="w-[75px] shrink-0">
                <img src={item.img} alt={item.alt} class="w-full h-auto" />
              </div>
              <div class="ml-2.5">
                <h4 class="text-22 font-semibold mb-2.5">{item.title}</h4>
                <p class="text-sm">{item.description}</p>
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>

  <div class="bg-[#f2f2f2] pt-4">
    <Section center={true}>
      <h2 class="mb-10 text-xl md:text-4xl">
        Join over 50,000 drivers using <span class="text-green"
          >FIXD Premium</span
        > to Handle Any Car Issue With Confidence
      </h2>
      <div class="space-y-5">
        {#each reviews as review}
          <div class="flex flex-col text-center bg-white rounded-2xl py-5 px-8">
            <div class="shrink-0 pt-5">
              <img src={review.img} alt={review.name} class="h-14 md:h-16" />
            </div>
            <div class="text-left md:ml-10 space-y-4">
              <div class="flex md:hidden justify-center mt-4">
                {#each new Array(5) as _}
                  <FaStarFilledRound color="green" size="lg" />
                {/each}
              </div>
              <div class="hidden md:flex">
                {#each new Array(5) as _}
                  <FaStarFilledRound color="green" size="xs" />
                {/each}
              </div>
              <div class="text-center md:text-left">
                <p class="leading-5 text-sm md:text-base">
                  "{review.description}"
                </p>
                <p class="font-bold text-sm">{review.name}</p>
              </div>
            </div>
          </div>
        {/each}
      </div>
    </Section>
  </div>

  <div class="max-w-[940px] mx-auto md:bg-[#ffffff] bg-[#fdfdfd] pb-10">
    <div class="border rounded-2xl px-2.5 md:px-7 py-4 mt-8">
      <div class="text-center">
        <img
          src={PremiumLogo}
          class="w-3/4 md:w-1/2 max-w-[440px]"
          alt="FIXD premium logo"
        />
        <h1 class="text-2xl md:text-4xl my-5">
          Your Exclusive 50% OFF Offer Includes:
        </h1>
      </div>
      <div class="space-y-1">
        {#each premiumFeatures as item}
          <p>{item}</p>
        {/each}
      </div>
      <div class="text-center mt-5">
        <button class="rounded-button" on:click={linkToCheckout}>
          <span class="text-lg">Get 50% Off Premium</span>
        </button>
      </div>
    </div>
  </div>

  <div class="max-w-[900px] mx-auto shadow py-14">
    <div class="text-center">
      <h2 class="text-xl md:text-2xl italic">"Is FIXD Premium Worth It?"</h2>
      <h2 class="text-xl md:text-2xl">And Other Common Questions, Answered.</h2>

      <div class="w-full md:w-3/4 mx-auto px-4 md:px-0 my-5">
        <button class="rounded-button" on:click={linkToCheckout}>
          <p class="font-bold md:font-medium text-sm md:text-lg py-2 px-10">
            No Thanks, I'm Ready to Upgrade Now
          </p>
        </button>
      </div>
    </div>
    <FAQ />
  </div>

  <Footer />
</main>
