<script>
  import { onMount } from 'svelte'

  import PaypalButton from '@/components/PaypalButton.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import api from '@/api'
  import Money from '@/util/money.mjs'
  import { clickOrEnterHandler } from '@/util/svelte.js'
  import { encodeURIParams } from '@/util/methods.js'

  import DoubleSensorImg from '@/images/landing-page/buybox/Sensor_Double.jpg?w=300'
  import TripleSensorImg from '@/images/landing-page/buybox/Sensor_Triple.jpg?w=300'
  import gallery1Img from '@/images/landing-page/buybox/1-gallery.jpg?w=300'
  import gallery2Img from '@/images/landing-page/buybox/2-gallery.jpg?w=300'
  import gallery3Img from '@/images/landing-page/buybox/3-gallery.jpg?w=300'
  import gallery4Img from '@/images/landing-page/buybox/4-gallery.jpg?w=300'
  import gallery5Img from '@/images/landing-page/buybox/5-gallery.jpg?w=300'
  import gallery6Img from '@/images/landing-page/buybox/6-gallery.jpg?w=300'
  import Tracker from '@/util/tracker.js'
  import {
    CheckmarkFilled,
    StarFilledRound,
    StarFilledSharp,
    Lightning,
    Timer,
    Fire,
    Hourglass,
  } from '@/components/icons'
  import Skeleton from '@/components/designs/Skeleton.svelte'

  import SingleSensor from '@/images/landing-page/scorpio/single-sensor.png'

  import ccIconsUrl from '@/images/landing-page/CreditCardIcons.png?height=105'

  const images = [
    { url: SingleSensor, altText: 'Single Sensor' },
    { url: gallery1Img },
    { url: gallery2Img },
    {
      url: gallery3Img,
      altText: `A Driver's Best Friend.
FIXD helps you save $1,000s in car repair by diagnostic 7,000+ car
problems from your smartphone.

Translates Engine Lights into Simple, Understandable Terms.
Get Maintenance Remainders.
Monitor Multiple Vehicles.
Easy Wireless Syncing.
Compatible with both Android and iOS devices.
`,
    },
    {
      url: gallery4Img,
      altText: `Engine Light Diagnosis
Shows why it's on in plain English and allows you to clear the light.
Severity: FIXD gives you the severity and consequences of each check
engine light so you know how quickly you need to get it taken care of.
Clear the Light: FIXD allows you to clear any check engine light, so if
it's just a loose gas cap or if you fix it yourself you don't need to go
to a repair shop.`,
    },
    {
      url: gallery5Img,
      altText: `Maintenance Reminders:
Get automatic alerts for all maintenance and oil changes.
Reminders: FIXD gives you automatic alerts of scheduled maintenance and oil
changes for  your exact vehicle. Forgetting scheduled maintenance can cause
thousands in costly car repairs.
Cost Estimate: FIXD gives you a cost estimate for each maintenance interval
based on your car and the parts and labor rates in your area.`,
    },
    {
      url: gallery6Img,
      altText: `Multi-Vehicle Control:
Link multiple vehicles together to monitor your family of vehicles.
All your cars: FIXD lets you see as many vehicles as you want, all from
one account.
Anywhere: FIXD lets you view your family's vehicles anywhere, anytime, right from
your smartphone.`,
    },
  ]

  export let funnel
  export let gateway
  export let landingPageCheckoutURL

  let selectedQuantity = getQuantity(funnel.default_initial_offer_slug)
  let selectedPack = getPack(funnel.default_initial_offer_slug)

  let buyboxElement

  $: selectedOffer =
    updateSelection(selectedQuantity, selectedPack) ??
    funnel.initial_offers.find(
      (o) => o.slug === funnel.default_initial_offer_slug,
    )
  $: selectedOfferSlug =
    selectedOffer?.slug ?? funnel.default_initial_offer_slug

  $: allowedQuantities = [
    ...new Set(funnel.initial_offers.map((o) => getQuantity(o.slug))),
  ].sort()
  $: allowedPacks = new Set(funnel.initial_offers.map((o) => getPack(o.slug)))

  $: selectedOfferStrikethroughPrice = selectedOffer.line_items.find(
    (offer) => offer.product.sku === '1001',
  ).strikethrough_price

  $: enabledExpressCheckouts =
    gateway.name === 'braintree'
      ? new Set(gateway.express_checkouts_enabled)
      : new Set()

  $: warrantyPrice =
    allowedPacks.has('w') &&
    new Money(
      funnel.initial_offers
        .find((o) => getPack(o.slug) === 'w')
        .line_items.find((li) => li.product.sku === 'LW01').price,
    )

  $: loadingPaypalButton = true

  function updateSelection(selectedQuantity, selectedPack) {
    return funnel.initial_offers.find((o) => {
      return (
        getQuantity(o.slug) === selectedQuantity &&
        getPack(o.slug) === selectedPack
      )
    })
  }

  // TODO: this could be a bit cleaner, it relies on slug naming conventions
  function getQuantity(offerSlug) {
    return offerSlug?.slice(0, 3)
  }

  function getPack(offerSlug) {
    return offerSlug?.substr(-1) === 'w' ? 'w' : ''
  }

  function generateCheckoutURL(selectedOfferSlug) {
    return api.checkouts.new.path({
      funnel_slug: funnel.slug,
      offer_slug: selectedOfferSlug,
    })
  }

  $: checkoutURL = generateCheckoutURL(selectedOfferSlug)

  $: landingPageCheckoutURL = checkoutURL

  onMount(() => {
    document.addEventListener('upsellery_analytics_updated', () => {
      checkoutURL = generateCheckoutURL(selectedOfferSlug)
    })
  })

  async function redirectToCheckoutUrl() {
    await Tracker.track('LP order now clicked')
    window.location.href = checkoutURL
  }

  let device_data = null

  function onPayPalLoad(ev) {
    ;({ device_data } = ev.detail)

    Tracker.track('LP Paypal', {
      variant: 'loaded',
    })
  }

  const onPayPalClick = () => {
    Tracker.track('LP Paypal', {
      variant: 'clicked',
    })
  }

  function onPaypalSubmit(ev) {
    const { payload } = ev.detail

    const params = {
      device_data: JSON.stringify(device_data),
      paypal: JSON.stringify(payload),
    }
    Tracker.track('LP Paypal', {
      variant: 'submitted',
    })
    window.location.href = `${checkoutURL}?${encodeURIParams(params)}`
  }

  const changePackCount = (quantity) => {
    selectedQuantity = quantity
    switch (quantity) {
      case '1pk':
        images[0] = { url: SingleSensor, altText: 'Single Sensor' }
        break
      case '2pk':
        images[0] = { url: DoubleSensorImg }
        break
      case '3pk':
        images[0] = { url: TripleSensorImg }
        break

      default:
        images[0] = { url: SingleSensor, altText: 'Single Sensor' }
        break
    }
  }

  const originalPrice = 59.99
  const defaultFunnel = funnel.initial_offers.find(
    (o) => o.slug === funnel.default_initial_offer_slug,
  )
  const priceOfSensor = defaultFunnel.subtotal_price.amount
  const percentageOff = Math.round(
    ((originalPrice - priceOfSensor) / originalPrice) * 100,
  )
</script>

<style>
  .packs button {
    border: 2px solid var(--fixd-green);
    color: var(--fixd-green);
    font-size: var(--font-size-small);
    background-color: transparent;
    flex: 1;
    padding: 0;
    border-radius: 24px;
    height: 36px;
  }

  @media only screen and (width >= 768px) {
    .packs button {
      border: 2px solid var(--mid-grey);
      color: var(--mid-grey);
      font-size: var(--font-size-xsmall);
      background-color: transparent;
      flex: 1;
      padding: 0.5em 0;
      border-radius: 4px;
    }

    .buy-box {
      box-shadow: 1px 2px 3px rgb(0 0 0 / 50%);
    }
  }

  .quantity-button {
    background-color: #f6f6f6;
    width: 24px;
    height: 100%;
  }

  .custom-button {
    padding-left: 0;
    padding-right: 0;
  }

  .packs button.selected {
    background-color: var(--fixd-green);
    border: 2px solid var(--fixd-green);
    color: var(--primary-bg-color);
  }
</style>

<div
  class="px-2.5 rounded-2xl text-left font-inter py-5 buy-box md:font-montserrat"
  bind:this={buyboxElement}
>
  <div class="md:flex items-center">
    <div class="items-center basis-1/2">
      <div class="w-full h-inherit mb-4 md:mb-0 md:mr-0">
        <Carousel showPagination={false} showArrows={true}>
          {#each images as item}
            <CarouselItem>
              <div class="flex items-center justify-center h-full">
                <img
                  src={item.url}
                  alt={item.altText}
                  class="w-3/5 {item.altText === 'Single Sensor'
                    ? ''
                    : 'md:w-4/5'}"
                />
              </div>
            </CarouselItem>
          {/each}
        </Carousel>
      </div>
      <div class="md:hidden">
        <h2 class="semi-bold">FIXD Sensor</h2>
        <p class="leading-5">
          OBD2 Professional Bluetooth Scan Tool & Code Reader for iPhone and
          Android
        </p>
        <div class="flex justify-between my-1">
          <p class="text-grey text-sm">1400+ sold in the last 24 hours</p>
          <div class="flex items-center">
            <div class="flex space-x-0.5">
              {#each new Array(5) as _}
                <StarFilledRound color="yellow" size="xs" />
              {/each}
            </div>
            <div class="ml-1">
              <p class="font-bold text-xs text-black-1">12K</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2 md:hidden">
        <div
          class="flex flex-wrap items-center leading-6 gap-1 justify-between"
        >
          <div class="flex items-center">
            <span class="text-2xl text-green font-semibold mr-1"
              >{new Money(selectedOffer.subtotal_price).toString()}</span
            >
            <span class="strikethrough text-red md:hidden"
              >reg ${selectedOfferStrikethroughPrice.amount}</span
            >
          </div>
          <div
            class="bg-yellow text-black-1 py-1 pl-2 pr-4 rounded-sm relative"
          >
            <p class="text-10 font-bold leading-tight">
              #1 Best Seller in Car Diagnostics
            </p>
            <div
              class="absolute top-0 right-[-14px] bg-white h-[21px] w-[21px] rotate-45"
            />
          </div>
        </div>
      </div>

      <div class="border-2 border-t-0 border-green md:hidden">
        <div class="bg-green px-2 py-0.5">
          <div class="flex items-center text-white">
            <p class="text-white italic font-semibold text-sm">
              Lightning Deal
            </p>
            <div class="mx-2">|</div>
            <div class="relative flex items-center">
              <div class="h-[6px] rounded-l-2xl bg-yellow w-[54px]" />
              <div class="absolute top-[-11px] right-2">
                <Timer color="green" size="sm" />
              </div>
              <div class="h-[6px] rounded-r-2xl bg-white w-[10px]" />
            </div>
            <p class="text-sm pl-2">Ending Soon</p>
          </div>
        </div>
        <div class="pl-2 space-y-1 py-2">
          <div class="flex items-center gap-2">
            <Lightning color="green" size="sm" />
            <p class="font-semibold text-xs">GET {percentageOff}% OFF FIXD!</p>
          </div>
          <div class="flex items-center gap-2">
            <Lightning color="green" size="sm" />
            <p class="font-semibold text-xs">
              Free Shipping, <span class="text-green italic"
                >96% Delivered Within 3-5 Days</span
              >
            </p>
          </div>
          <div class="flex items-center gap-2">
            <Lightning color="green" size="sm" />
            <p class="font-semibold text-xs">
              Free 1 Year Warranty, <span class="text-green italic">
                Free Returns & Replacements</span
              >
            </p>
          </div>
          <div class="flex items-center gap-2">
            <Lightning color="green" size="sm" />
            <p class="font-semibold text-xs">
              100% Risk Free Money Back Guarantee
            </p>
          </div>
          <div>
            <div class="flex items-center">
              <p class="font-bold pr-3">Qty</p>
              <div
                class="flex items-center font-roboto font-bold border border-[#dbdbdb] h-8"
              >
                <button
                  class="bare quantity-button"
                  disabled={selectedQuantity === '1pk'}
                  class:text-grey={selectedQuantity === '1pk'}
                  on:click={() => {
                    switch (selectedQuantity) {
                      case '3pk':
                        changePackCount('2pk')
                        break
                      case '2pk':
                        changePackCount('1pk')
                        break
                      default:
                        changePackCount('1pk')
                        break
                    }
                  }}>—</button
                >
                <div class="px-4 border-[#dbdbdb] h-full border-x pt-[1px]">
                  <p class="text-lg">{selectedQuantity[0]}</p>
                </div>
                <button
                  disabled={selectedQuantity === '3pk'}
                  class:text-grey={selectedQuantity === '3pk'}
                  class="bare quantity-button"
                  on:click={() => {
                    switch (selectedQuantity) {
                      case '1pk':
                        changePackCount('2pk')
                        break
                      case '2pk':
                        changePackCount('3pk')
                        break
                      default:
                        changePackCount('2pk')
                        break
                    }
                  }}>+</button
                >
              </div>
              <div class="text-green flex items-center pl-4">
                <Hourglass color="green" size="sm" />
                <p class="text-sm pl-2">Only 19 left at this price!</p>
              </div>
            </div>
          </div>
          {#if allowedPacks.has('w')}
            <div>
              <p class="text-xs font-bold mb-1">Upgrade Your Protection Plan</p>
              <div class="flex items-center flex-row gap-3">
                <div class="mr-1.5">
                  <input
                    type="checkbox"
                    class="bg-grey-300 border-0 outline-none"
                    use:clickOrEnterHandler={(event) => {
                      event.target.checked
                        ? (selectedPack = 'w')
                        : (selectedPack = '')
                    }}
                  />
                  <span class="text-xs"
                    >Lifetime Warranty - <span class="font-bold"
                      >{warrantyPrice.toString()}</span
                    ></span
                  >
                </div>
              </div>
            </div>
          {/if}
        </div>

        <img
          class="h-[35px] hidden md:block"
          src={ccIconsUrl}
          alt="We accept Visa, MasterCard, American Express, and Paypal"
        />
      </div>
    </div>

    <div class="basis-1/2">
      <div class="description hidden md:block">
        <div class="flex md:block ml-3 md:ml-0">
          <h3 class="text-22 hidden md:block">
            FIXD OBD2 Professional Bluetooth Scan Tool & Code Reader for iPhone
            and Android
          </h3>
          <div class="my-2">
            <div>
              <div class="flex flex-wrap items-center leading-6 mb-1">
                <span class="text-2xl text-green font-semibold mr-1"
                  >{new Money(selectedOffer.subtotal_price).toString()}</span
                >
                <span class="strikethrough text-red md:hidden"
                  >reg ${selectedOfferStrikethroughPrice.amount}</span
                >
              </div>
              <p
                class="text-sm italic block md:inline-block md:text-base -mt-1"
              >
                * Limit one per household
              </p>
            </div>

            <div class="hidden md:block">
              {#each new Array(5) as _}
                <StarFilledSharp color="yellow" size="sm" />
              {/each}
            </div>
            <div class="font-normal md:font-semibold text-sm my-1">
              <div class="flex items-center">
                <div class="mr-2 shrink-0">
                  <CheckmarkFilled color="green" size="sm" />
                </div>
                <p class="leading-4">Free Shipping</p>
              </div>
              <div class="flex items-center md:hidden">
                <div class="mr-2 shrink-0 mb-1">
                  <CheckmarkFilled color="green" size="sm" />
                </div>
                <p class="leading-4">Free 1-Year Warranty</p>
              </div>
              <div class="flex items-center">
                <div class="mr-2 shrink-0">
                  <CheckmarkFilled color="green" size="sm" />
                </div>
                <p class="leading-4">100% Risk Free Money Back Guarantee</p>
              </div>
            </div>
            <img
              class="h-[35px] hidden md:block"
              src={ccIconsUrl}
              alt="We accept Visa, MasterCard, American Express, and Paypal"
            />
          </div>
        </div>

        <div class="hidden md:block">
          <div class="packs flex flex-row flex-wrap gap-2">
            {#each allowedQuantities as quantity}
              <button
                class="button-{quantity} text-lg custom-button"
                class:selected={selectedQuantity === quantity}
                on:click={() => changePackCount(quantity)}
              >
                {quantity.slice(0, 1)} Pack
              </button>
            {/each}
          </div>
          {#if allowedPacks.has('w')}
            <div class="warranty hidden md:block">
              <div class="my-2">
                <input
                  type="radio"
                  name="1-year-warranty"
                  aria-labelledby="1-year-warranty-label"
                  bind:group={selectedPack}
                  value=""
                />
                <label
                  class="text-sm"
                  for="1-year-warranty"
                  id="1-year-warranty-label"
                  use:clickOrEnterHandler={() => (selectedPack = '')}
                >
                  1 Year Warranty
                  <span class="text-green text-base">(Free!)</span>
                </label>
              </div>
              <div class="mb-2">
                <input
                  type="radio"
                  name="lifetime-warranty"
                  aria-labelledby="lifetime-warranty-label"
                  bind:group={selectedPack}
                  value="w"
                />
                <label
                  for="1-year-warranty"
                  id="lifetime-warranty-label"
                  class="text-sm"
                  use:clickOrEnterHandler={() => (selectedPack = 'w')}
                >
                  Lifetime Warranty
                  <span class="text-green text-base"
                    >({warrantyPrice.toString()})</span
                  >
                </label>
              </div>
            </div>
          {/if}
        </div>
      </div>

      <div>
        <div class="mt-2 md:hidden w-full">
          <Carousel
            showPagination={false}
            loop={true}
            splideOptions={{
              perPage: 1,
              autoplay: true,
              interval: 3000,
            }}
          >
            <CarouselItem>
              <div class="flex items-center justify-center gap-1 w-full">
                <Fire color="green" size="sm" />
                <p class="text-green text-xs">
                  Best seller for the past 30 days
                </p>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="flex items-center justify-center gap-1 w-full">
                <Hourglass color="green" size="sm" />
                <p class="text-green text-xs">Selling fast! Get yours today!</p>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="flex items-center justify-center gap-1 w-full">
                <CheckmarkFilled color="green" size="sm" />
                <p class="text-green text-xs">
                  Recently, 74 people gave this a 5 star rating
                </p>
              </div>
            </CarouselItem>
          </Carousel>
        </div>

        <div class="w-full mt-3 md:px-0 md:mt-0 order-button">
          <div class="gap-3 md:block items-center w-full space-y-2">
            <div class="flex-1">
              <div class="w-full">
                <button
                  class="rounded-button custom-button"
                  on:click={() => redirectToCheckoutUrl()}
                >
                  Order Now
                </button>
              </div>
            </div>

            {#if enabledExpressCheckouts.has('paypal')}
              <div class="relative min-h-[50px] flex-1">
                <div
                  class="absolute z-20 top-0 left-0 h-[50px] w-full"
                  class:hidden={!loadingPaypalButton}
                >
                  <Skeleton height="50" shape="pill" />
                </div>

                <PaypalButton
                  shape="pill"
                  bind:loading={loadingPaypalButton}
                  height={50}
                  extraClasses="min-h-[50px]"
                  tokenizationKey={gateway.tokenization_key}
                  on:click={onPayPalClick}
                  on:load={onPayPalLoad}
                  on:submit={onPaypalSubmit}
                  on:mockCheckout={() => console.log('Mock PayPal checkout.')}
                />
              </div>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
