<script>
  import Benefits from '@/components/landing-page/Benefits.svelte'
  import Compatibility from '@/components/landing-page/Compatibility.svelte'
  import AsSeenOn from '@/components/landing-page/AsSeenOn.svelte'
  import OtherOBD2 from '@/components/landing-page/OtherOBD2.svelte'
  import TryRiskFree from '@/components/landing-page/TryRiskFree.svelte'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'
  import Mission from '@/components/landing-page/Mission.svelte'
  import FAQ from '@/components/landing-page/FAQ.svelte'
  import About from '@/components/landing-page/About.svelte'
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Banner from '@/components/landing-page/Banner.svelte'
  import { Lightning } from '@/components/icons'

  import BuyBoxL004 from '@/pages//scorpio/components/L004/BuyBoxL004.svelte'
  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  import Money from '@/util/money.mjs'

  export let funnel
  export let gateway

  let landingPageCheckoutURL = ''

  // TODO: load this from funnel properties rather than hard-coding
  const fullPrice = new Money({ amount: 59.99, currency: 'USD' })
  const price = new Money({ amount: 24.99, currency: 'USD' })
</script>

<main class="md:pb-0">
  <div class="block md:hidden">
    <div class="flex font-inter items-center leading-tight">
      <div class="bg-yellow py-1 px-2 h-8">
        <p class="uppercase font-bold text-10 text-center">
          Lightning <br /> deal
        </p>
      </div>
      <div class="bg-green flex gap-2 items-center pl-1.5 h-8 w-full">
        <Lightning color="white" size="sm" />
        <p class="text-white my-0 text-center font-semibold text-xs">
          58% OFF + FREE SHIPPING + FREE WARRANTY
        </p>
      </div>
    </div>
    <Banner
      showMenu={true}
      height="small"
      fillShoppingCardIcon={false}
      buyUrl="/buy/sensor25"
    />
  </div>

  <div class="hidden md:block">
    <div class="banner bg-green">
      <p class="text-white my-0 text-center font-bold">Get 58% off FIXD</p>
    </div>
    <Banner buyUrl="/buy/sensor25" />
  </div>

  <Section center="true" verticalPadding="short">
    <div class="block md:hidden">
      <div class="font-inter mt-[-8px]">
        <h1 class="text-lg sm:text-4xl title bold" id="bannerHeader">
          Decode check engine lights instantly
        </h1>
      </div>
    </div>
    <div class="hidden md:block">
      <div class="mb-5 font-inter">
        <h1 class="text-lg sm:text-4xl title bold">
          Decode check engine lights instantly
        </h1>

        <h3 class="my-5 text-base sm:text-xl">
          LIMITED OFFER: FIXD is now <span class="strikethrough"
            >{new Money(fullPrice).toString()}</span
          > <span class="text-green">{new Money(price).toString()}</span>
          +
          <span class="text-green">Free Shipping</span>
          + <span class="text-green">Free 1-Year Warranty!</span>
        </h3>
      </div>
    </div>

    <div class="max-w-screen-md mx-auto">
      <BuyBoxL004 {funnel} {gateway} bind:landingPageCheckoutURL />
    </div>
  </Section>

  <div id="asSeenOn">
    <AsSeenOn />
  </div>

  <Benefits />

  <OtherOBD2 {price}>
    <JumpToBuyBoxButton slot="cta" checkoutURL={landingPageCheckoutURL}>
      <span class="uppercase">Get FIXD NOW 58% OFF</span>
    </JumpToBuyBoxButton>
  </OtherOBD2>

  <Compatibility>
    <JumpToBuyBoxButton
      slot="cta"
      maxWidth={56}
      checkoutURL={landingPageCheckoutURL}
    >
      <span class="uppercase">BUY FIXD NOW</span>
    </JumpToBuyBoxButton>
  </Compatibility>

  <LiveReviews />

  <TryRiskFree percentage={58}>
    <JumpToBuyBoxButton
      slot="cta"
      size="medium"
      checkoutURL="{landingPageCheckoutURL}p"
    >
      <span class="uppercase">Get FIXD NOW 58% OFF</span>
    </JumpToBuyBoxButton>
  </TryRiskFree>

  <Mission />

  <FAQ />

  <About />

  <Footer />
</main>
