<script>
  import { fly } from 'svelte/transition'

  import Caret from '@/components/Caret.svelte'

  export let images = [] // {["url","altText"]}
  export let index = 0
  const animParams = { x: -25 }

  $: selectedImage = images[index]

  function left() {
    animParams.x = 25
    index = index === 0 ? images.length - 1 : index - 1
  }
  function right() {
    animParams.x = -25
    index = (index + 1) % images.length
  }
</script>

<style>
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .image {
    flex-grow: 1;
    min-width: none;
  }

  .caret {
    margin: 5px;
  }
</style>

<!--
  Prefetch all the images in the gallery so that the layout
  doesn't shift as the image loads. 

	Note that in current browsers preload/prefetch don't work with responsive
	image loading with <picture>: https://web.dev/preload-responsive-images/

	If you want prefetch, use a single size and format.
-->
<svelte:head>
  {#each images as { url }}
    <link rel="prefetch" as="image" href={url} />
  {/each}
</svelte:head>

{#if images.length > 0}
  <div class="container max-w-screen-2xl">
    {#if images.length > 1}
      <button class="bare caret" aria-label="left" on:click={() => left()}>
        <Caret direction="left" scale="2" color="var(--fixd-green)" />
      </button>
    {/if}
    {#key index}
      <div class="image" in:fly={animParams}>
        <img
          src={selectedImage.url}
          width="100%"
          alt={selectedImage.altText || ''}
        />
      </div>
    {/key}
    {#if images.length > 1}
      <button class="bare caret" aria-label="right" on:click={() => right()}>
        <Caret direction="right" scale="2" color="var(--fixd-green)" />
      </button>
    {/if}
  </div>
{/if}
