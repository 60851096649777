<script>
  import Gallery from '@/components/Gallery.svelte'

  import fixdProduct from '@/images/fixd-product.png?width=500'
  import fixdReview from '@/images/FIXD-review-2.jpg?width=500'
  import carInfo from '@/images/info-about-car.jpg?width=500'
  import plugFixd from '@/images/plug-fixd-in.jpg?width=500'

  const images = [
    {
      url: plugFixd,
      altText: 'A woman plugging the FIXD Sensor into a vehicle',
    },
    {
      url: carInfo,
      altText: 'The FIXD app displaying information about a car',
    },
    {
      url: fixdReview,
      altText:
        '5 Star review: FIXD saved me... already paid for itself. I plugged the device into the port and downloaded the app. 2 minutes tops and I was able to see what was wrong. Thank you FIXD.',
    },
    { url: fixdProduct, altText: 'The FIXD sensor' },
  ]
</script>

<style>
  .gallery {
    max-width: 100%;
    margin: auto;
    flex: 1 1 50%;
  }
</style>

<div class="gallery">
  <Gallery {images} />
</div>
