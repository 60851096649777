<script>
  import Benefits from '@/components/landing-page/Benefits.svelte'
  import Compatibility from '@/components/landing-page/Compatibility.svelte'
  import AsSeenOn from '@/components/landing-page/AsSeenOn.svelte'
  import OtherOBD2 from '@/components/landing-page/OtherOBD2.svelte'
  import TryRiskFree from '@/components/landing-page/TryRiskFree.svelte'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'
  import Mission from '@/components/landing-page/Mission.svelte'
  import FAQ from '@/components/landing-page/FAQ.svelte'
  import About from '@/components/landing-page/About.svelte'
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Banner from '@/components/landing-page/Banner.svelte'
  import Experiment from '@/components/Experiment.svelte'

  import Scanner from '@/images/landing-page/scorpio/premium-clarity/scanner.gif'
  import ThreeCellPhones from '@/pages/scorpio/premium-clarity/components/ThreeCellPhones.svelte'
  import EverythingList from '@/pages/scorpio/premium-clarity/components/EverythingList.svelte'
  import OnlyScanner from '@/pages/scorpio/premium-clarity/components/OnlyScanner.svelte'
  import Guarantee from '@/pages/scorpio/premium-clarity/components/Guarantee.svelte'
  import Faq from '@/pages/scorpio/premium-clarity/components/Faq.svelte'
  import AboutUs from '@/pages/scorpio/premium-clarity/components/AboutUs.svelte'
  import TryFixd from '@/pages/scorpio/premium-clarity/components/TryFIXD.svelte'
  import CompatibilityWidget from '@/pages/scorpio/premium-clarity/components/CompatibilityWidget.svelte'

  import {
    Lightning,
    SalesTag,
    ShippingTruck,
    Certificate,
  } from '@/components/icons'

  import BuyBoxL007 from '@/pages//scorpio/components/L007/BuyBoxL007.svelte'
  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  import Money from '@/util/money.mjs'

  export let funnel
  export let gateway

  let landingPageCheckoutURL = ''

  // TODO: load this from funnel properties rather than hard-coding
  const price = new Money({ amount: 19.99, currency: 'USD' })
</script>

<main class="md:pb-0">
  <Experiment name="LP-2024-09-18-L035-PC-Button-Text" let:value>
    <div class="hidden md:block">
      <div class="banner bg-green py-2.5">
        <div class="flex justify-evenly w-100 text-white font-bold font-inter">
          <div class="flex items-center">
            <div class="mr-3">
              <SalesTag color="white" />
            </div>
            <p class="text-xl">Get 67% off!</p>
          </div>
          <div class="flex basis-1/3 border-x justify-center item-center">
            <div class="mr-3">
              <ShippingTruck color="white" />
            </div>
            <p class="text-xl">Free Shipping</p>
          </div>
          <div class="flex">
            <div class="mr-3">
              <Certificate color="white" />
            </div>
            <p class="text-xl">Free 1 Year Warranty</p>
          </div>
        </div>
      </div>
      <Banner />
    </div>

    <div class="block md:hidden">
      <div class="flex font-inter items-center leading-tight">
        <div class="bg-yellow py-1 px-2 h-8">
          <p class="uppercase font-bold text-10 text-center">
            Lightning <br /> deal
          </p>
        </div>
        <div class="bg-green flex gap-2 items-center pl-1.5 h-8 w-full">
          <Lightning color="white" size="sm" />
          <p class="text-white my-0 text-center font-semibold text-xs">
            67% OFF + FREE SHIPPING + FREE WARRANTY
          </p>
        </div>
      </div>
      <Banner showMenu={true} height="small" fillShoppingCardIcon={false} />
    </div>

    <Section center="true" verticalPadding="none">
      <div class="pt-5">
        <div class="block md:hidden">
          <div class="font-inter mt-[-8px]">
            <h1 class="text-lg sm:text-4xl title bold" id="bannerHeader">
              Decode check engine lights instantly
            </h1>
          </div>
        </div>
      </div>
    </Section>

    <div class="max-w-[1440px] mx-auto">
      <BuyBoxL007
        {funnel}
        {gateway}
        bind:landingPageCheckoutURL
        changeLightningDealOrder={value === 'Premium Clarity'}
      />
    </div>

    <div id="asSeenOn">
      <AsSeenOn />
    </div>

    {#if value === 'Premium Clarity'}
      <ThreeCellPhones />

      <Section
        center="true"
        maxWidth="xl"
        background="dark2"
        additionalClass="rounded-xl md:rounded-none mx-3 md:mx-0 mb-5 md:mb-0"
      >
        <div class="text-white leading-tight">
          <p>⭐⭐⭐⭐⭐</p>

          <div class="mt-6 mb-8">
            <p class="text-xl font-medium">
              “Highly accurate diagnosis that has saved me thousands in
              repairs.”
            </p>
          </div>

          <div>
            <p>- Stan Leaf</p>
            <p class="font-semibold">Verified Purchase - March 6, 2024</p>
            <p class="italic">21 people found this helpful.</p>
          </div>
        </div>
      </Section>

      <div class="block md:hidden">
        <Section center="true" maxWidth="small">
          <h2>FIXD Does So Much More Than Read Engine Codes...</h2>

          <div>
            <img src={Scanner} alt="scanner" />
          </div>

          <div class="px-12">
            <JumpToBuyBoxButton size="full">
              <span class="text-22">Try FIXD For $19.99</span>
            </JumpToBuyBoxButton>
          </div>
        </Section>
      </div>

      <EverythingList>
        <div class="text-center hidden md:block md:mt-12" slot="cta">
          <JumpToBuyBoxButton>
            <span class="text-22">Try FIXD For $19.99</span>
          </JumpToBuyBoxButton>
        </div>
      </EverythingList>

      <Section
        center="true"
        maxWidth="xl"
        background="dark2"
        additionalClass="rounded-xl md:rounded-none mx-3 md:mx-0"
      >
        <div class="text-white leading-tight">
          <p>⭐⭐⭐⭐⭐</p>

          <div class="mt-6 mb-8">
            <p class="text-xl font-medium">
              “This little gadget is like having a mechanic in your pocket. It's
              saved my butt more times than I can count.”
            </p>
          </div>

          <div>
            <p>- Joe Goode</p>
            <p class="font-semibold">Verified Purchase - April 2, 2023</p>
            <p class="italic">36 people found this helpful.</p>
          </div>
        </div>
      </Section>

      <OnlyScanner>
        <div class="text-center mt-5 md:mt-12 px-12" slot="cta">
          <JumpToBuyBoxButton size="full">
            <span class="text-22">Get FIXD 67% Off</span>
          </JumpToBuyBoxButton>
        </div>
      </OnlyScanner>

      <CompatibilityWidget>
        <JumpToBuyBoxButton slot="compatible" maxWidth={56}>
          <span class="uppercase">BUY FIXD NOW</span>
        </JumpToBuyBoxButton>
      </CompatibilityWidget>

      <Guarantee>
        <div class="text-center mt-4 md:mt-12" slot="cta">
          <JumpToBuyBoxButton size="full">
            <span class="text-22">Try FIXD Risk-Free</span>
          </JumpToBuyBoxButton>
        </div>
      </Guarantee>

      <LiveReviews />

      <Faq />

      <AboutUs />

      <TryFixd>
        <div class="text-center mt-5" slot="cta">
          <JumpToBuyBoxButton size="full">
            <span class="text-22">Claim This Offer</span>
          </JumpToBuyBoxButton>
        </div>
      </TryFixd>

      <Section center={true} background="green" verticalPadding="short">
        <div class="text-white leading-tight font-semibold">
          <p>Copyright 2024. All Rights Reserved.</p>
          <p>Made with love in Atlanta, GA.</p>
        </div>
      </Section>
    {:else}
      <Benefits />

      <OtherOBD2 {price}>
        <JumpToBuyBoxButton slot="cta" checkoutURL={landingPageCheckoutURL}>
          <span class="uppercase">Get FIXD NOW 67% OFF</span>
        </JumpToBuyBoxButton>
      </OtherOBD2>

      <Compatibility>
        <JumpToBuyBoxButton
          slot="cta"
          maxWidth={56}
          checkoutURL={landingPageCheckoutURL}
        >
          <span class="uppercase">BUY FIXD NOW</span>
        </JumpToBuyBoxButton>
      </Compatibility>

      <LiveReviews />

      <TryRiskFree>
        <JumpToBuyBoxButton
          slot="cta"
          size="medium"
          checkoutURL="{landingPageCheckoutURL}p"
        >
          <span class="uppercase">Get FIXD NOW 67% OFF</span>
        </JumpToBuyBoxButton>
      </TryRiskFree>

      <Mission />

      <FAQ />

      <About />

      <Footer />
    {/if}
  </Experiment>
</main>
