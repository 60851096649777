<script>
  import Footer from '@/components/landing-page/webflow/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import Banner from '@/components/landing-page/Banner.svelte'
  import Benefits from '@/components/landing-page/webflow/Benefits.svelte'
  import FAQ from '@/components/landing-page/webflow/FAQ.svelte'
  import About from '@/components/landing-page/webflow/About.svelte'
  import BuyBoxV2 from '@/components/landing-page/widgets/BuyBoxV2.svelte'

  import AvailableOn from '@/components/landing-page/webflow/AvailableOn.svelte'
  import ProblemAnxiety from '@/components/landing-page/webflow/ProblemAnxiety.svelte'
  import HowItWorks from '@/components/landing-page/webflow/HowItWorks.svelte'
  import UnderstandYourCar from '@/components/landing-page/webflow/UnderstandYourCar.svelte'
  import RealTimeHealthMonitoring from '@/components/landing-page/webflow/RealTimeHealthMonitoring.svelte'
  import MoreReasons from '@/components/landing-page/webflow/MoreReasons.svelte'
  import PeopleLove from '@/components/landing-page/webflow/PeopleLove.svelte'
  import StartUsing from '@/components/landing-page/webflow/StartUsing.svelte'
  import MobileApp from '@/components/landing-page/webflow/MobileApp.svelte'
  import Satisfaction from '@/components/landing-page/webflow/Satisfaction.svelte'
  import PaymentMethods from '@/components/landing-page/webflow/PaymentMethods.svelte'
  import PricePoints from '@/components/landing-page/webflow/PricePoints.svelte'
  import JumpToBuyBoxButton from '../components/landing-page/webflow/JumpToBuyBoxButton.svelte'

  import Money from '@/util/money.mjs'

  export let funnel
  export let gateway

  // TODO: load this from funnel properties rather than hard-coding
  const fullPrice = new Money({ amount: 59.99, currency: 'USD' })
  const price = new Money({ amount: 19.99, currency: 'USD' })
</script>

<main class="text-black">
  <div class="banner bg-green">
    <p class="text-white my-0 text-center font-bold">Get 67% off FIXD</p>
  </div>
  <Banner />

  <Section center="true" verticalPadding="short">
    <div class="mb-5 font-inter">
      <h1 class="text-lg sm:text-4xl title bold">
        Decode check engine lights instantly
      </h1>

      <h3 class="my-5 text-base sm:text-xl">
        LIMITED OFFER: FIXD is now <span class="strikethrough"
          >{new Money(fullPrice).toString()}</span
        > <span class="text-green">{new Money(price).toString()}</span>
        +
        <span class="text-green">Free Shipping</span>
        + <span class="text-green">Free 1-Year Warranty!</span>
      </h3>
    </div>

    <div class="max-w-screen-md mx-auto">
      <BuyBoxV2 {funnel} {gateway} />
    </div>
  </Section>

  <Benefits>
    <div class="mt-2.5" slot="cta">
      <JumpToBuyBoxButton trackName="Prevent 1000 CTA" />
    </div>
  </Benefits>
  <AvailableOn />
  <ProblemAnxiety>
    <div class="mt-2.5 mb-6" slot="cta">
      <JumpToBuyBoxButton trackName="Say Goodybye CTA" />
    </div>
  </ProblemAnxiety>
  <HowItWorks />
  <UnderstandYourCar />
  <RealTimeHealthMonitoring>
    <div class="mt-2.5 w-full md:w-auto mb-6 md:mb-0" slot="cta">
      <JumpToBuyBoxButton trackName="Gif CTA" />
    </div>
  </RealTimeHealthMonitoring>

  <MoreReasons />

  <PeopleLove>
    <div class="mt-2.5 mb-4" slot="cta">
      <JumpToBuyBoxButton trackName="Review CTA" />
    </div>
  </PeopleLove>

  <StartUsing />
  <MobileApp />
  <About />
  <Satisfaction />
  <div class="xs:hidden">
    <PricePoints />
  </div>
  <PaymentMethods />

  <FAQ />
  <Footer />
</main>
