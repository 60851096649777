<script>
  import FAQ from '@/components/landing-page/FAQ.svelte'
  import ThreeFold from '@/components/ThreeFold.svelte'
  import Compatibility from '@/components/landing-page/Compatibility.svelte'
  import InsideFixdApp from '@/components/landing-page/InsideFixdApp.svelte'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'
  import AsSeenOn from '@/components/landing-page/AsSeenOn.svelte'
  import Footer from '@/components/landing-page/Footer.svelte'
  import FIXDProductCarousel from '@/components/landing-page/widgets/FIXDProductCarousel.svelte'

  import Money from '@/util/money.mjs'

  import carIcon from '@/images/car-icon-ccms.png?width=100'
  import CELicon from '@/images/CEL-icon-ccms.png?width=100'
  import phoneIcon from '@/images/phone-icon-ccms.png?width=100'
  import sensorIcon from '@/images/sensor-icon-ccms.png?width=100'

  import Section from '@/components/landing-page/widgets/Section.svelte'

  import api from '@/api'
  import Banner from '../components/landing-page/Banner.svelte'

  export let funnel

  // TODO: load this from funnel properties rather than hard-coding
  // export let funnel
  const price = new Money({ amount: 19.99, currency: 'USD' })
  const premiumPricePerMonth = new Money({ amount: 5.83, currency: 'USD' })

  $: checkoutURL = api.checkouts.new.path({
    funnel_slug: funnel.slug,
    offer_slug: funnel.default_initial_offer_slug,
  })
</script>

<style>
  hr {
    width: 20%;
    margin: auto;
    border: solid var(--primary-color) 2px;
    background-color: var(--primary-color);
  }
</style>

<Banner showMenu={false} />

<Section verticalPadding="short">
  <ThreeFold>
    <div slot="title" class="text-left mb-4">
      <div class="mb-4">
        <h1 class="uppercase">FIXD OBD-II Sensor</h1>
      </div>
      <h3>{price.toString()}</h3>
    </div>
    <div slot="image">
      <FIXDProductCarousel />
    </div>
    <div slot="body">
      <!-- TODO: load this from funnel or use Buybox -->
      <div class="w-[93%] md:w-full mx-auto">
        <a class="button uppercase mb-4 mt-6 lg:mt-0 block" href={checkoutURL}>
          Add to cart
        </a>
      </div>
      <ul class="text-left pl-5 list-disc">
        <li>
          <span class="font-bold">Save time, money, and stress:</span> Our easy-to-use
          car scanner and app pays for itself by helping you quickly diagnose thousands
          of check engine related car problems before going to the mechanic.
        </li>
        <li>
          <span class="font-bold">Fix simple issues yourself:</span> See what your
          check engine light means in simple terms and access our library of how-to
          guides and videos to do repairs at home and save a bundle.
        </li>
        <li>
          <span class="font-bold">Protect the whole family:</span> Keep track of
          all your loved one's vehicle service needs by connecting multiple FIXD
          Sensors to your account. You'll see when they have an issue, when they
          need an oil change, and more, even if they're hundreds of miles from home.
        </li>
        <li>
          <span class="font-bold">Make your car last:</span> From letting you know
          if it's safe to keep driving to alerting you when it's time for important
          maintenance, FIXD helps you avoid costly breakdowns and extend the life
          of your vehicle.
        </li>
      </ul>
    </div>
  </ThreeFold>
</Section>

<hr />

<Section center="true">
  <h1>What's included?</h1>
  <div class="flex flex-col md:flex-row whats-included">
    <div class="text-center mx-6 my-1 md:m-2.5">
      <img src={sensorIcon} alt="Sensor Icon" class="max-w-[100px]" />
      <div class="mb-2">
        <h4>FIXD Sensor</h4>
      </div>
      <p>
        Connects your car to your smartphone for easy diagnostics and
        maintenance.
      </p>
    </div>
    <div class="text-center mx-6 my-1 md:m-2.5">
      <img src={phoneIcon} alt="Phone Icon" class="max-w-[100px]" />
      <div class="mb-2">
        <h4>DIY Guidance & Car Care Community</h4>
      </div>
      <p>Video tutorials and pro-level guidance help make car care simple.</p>
    </div>
    <div class="text-center mx-6 my-1 md:m-2.5">
      <img src={CELicon} alt="Check Engine Light Icon" class="max-w-[100px]" />
      <div class="mb-2">
        <h4>Check Engine Light Diagnostics</h4>
      </div>
      <p>
        Translates 7,000+ “check engine” codes into everyday language - in
        seconds.
      </p>
    </div>
    <div class="text-center mx-6 my-1 md:m-2.5">
      <img src={carIcon} alt="Car Icon" class="max-w-[100px]" />
      <div class="mb-2">
        <h4>Maintenance Timeline & Alerts</h4>
      </div>
      <p>
        Eliminate maintenance guesswork to help keep your car in great shape!
      </p>
    </div>
  </div>
</Section>

<hr />

<Compatibility>
  <h1 slot="header">Does FIXD work on my car?</h1>
  <div class="my-4" slot="content">
    <p>
      FIXD works with all gas-powered cars and trucks built after 1996 and
      diesels built after 2008.
    </p>
  </div>
  <!-- <div slot="cta">TODO buy button</div> -->
</Compatibility>

<hr />

<InsideFixdApp {premiumPricePerMonth} />

<Section verticalPadding="short">
  <AsSeenOn />
</Section>

<Section center="true" background="very-light-grey">
  <div class="mb-4">
    <h1>How does FIXD work?</h1>
  </div>
  <div class="mx w-full mx-auto md:relative md:pb-[56.25%] md:h-0">
    <iframe
      class="w-full h-[316px] md:absolute md:top-0 md:left-0 md:h-full"
      src="https://www.youtube.com/embed/C2I-HDuSrKU"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</Section>

<FAQ />

<LiveReviews />

<Footer />
