<script>
  import Footer from '@/components/landing-page/webflow/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import Banner from '@/components/landing-page/Banner.svelte'
  import Benefits from '@/components/landing-page/webflow/Benefits.svelte'
  import FAQ from '@/components/landing-page/webflow/FAQ.svelte'
  import About from '@/components/landing-page/webflow/About.svelte'
  import BuyBoxV2 from '@/components/landing-page/widgets/BuyBoxV2.svelte'

  import AvailableOn from '@/components/landing-page/webflow/AvailableOn.svelte'
  import ProblemAnxiety from '@/components/landing-page/webflow/ProblemAnxiety.svelte'
  import HowItWorks from '@/components/landing-page/webflow/HowItWorks.svelte'
  import UnderstandYourCar from '@/components/landing-page/webflow/UnderstandYourCar.svelte'
  import RealTimeHealthMonitoring from '@/components/landing-page/webflow/RealTimeHealthMonitoring.svelte'
  import MoreReasons from '@/components/landing-page/webflow/MoreReasons.svelte'
  import PeopleLove from '@/components/landing-page/webflow/PeopleLove.svelte'
  import StartUsing from '@/components/landing-page/webflow/StartUsing.svelte'
  import MobileApp from '@/components/landing-page/webflow/MobileApp.svelte'
  import Satisfaction from '@/components/landing-page/webflow/Satisfaction.svelte'
  import PaymentMethods from '@/components/landing-page/webflow/PaymentMethods.svelte'
  import PricePoints from '@/components/landing-page/webflow/PricePoints.svelte'
  import JumpToBuyBoxButton from '@/components/landing-page/webflow/JumpToBuyBoxButton.svelte'
  import Compatibility from '@/components/landing-page/webflow/Compatibility.svelte'

  export let funnel
  export let gateway

  const scrollToBuyBox = () => {
    document.querySelector('.buy-box .order-button').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
</script>

<style>
  .buy-box-background {
    background-image: linear-gradient(135deg, #274161, #000);
  }
</style>

<main class="text-black">
  <div class="banner bg-green">
    <p class="text-white my-0 text-center font-bold">Get 67% off FIXD</p>
  </div>
  <Banner darkTheme={false} />

  <div class="buy-box-background pt-[15%] pb-[5%] md:pt-[5%]">
    <Section center="true" verticalPadding="short" horizontalPadding="none">
      <div class="mb-5 font-monserrat">
        <h1 class="text-white text-38 font-bold leading-8">67% OFF!</h1>

        <h1 class="text-green text-40 font-bold">Father's Day Sale</h1>
        <h2 class="text-white pt-2.5">
          <span class="strikethrough">$59</span> Only
          <span class="text-green">$19.99</span> for a limited time!
        </h2>
      </div>

      <div class="bg-white py-[5%] rounded-3xl">
        <div class="max-w-[325px] md:max-w-screen-md mx-auto">
          <BuyBoxV2 {funnel} {gateway} />
        </div>
      </div>
    </Section>
  </div>

  <Benefits>
    <div class="mt-2.5" slot="cta">
      <JumpToBuyBoxButton trackName="Prevent 1000 CTA" />
    </div>
  </Benefits>
  <AvailableOn />
  <ProblemAnxiety>
    <div class="mt-2.5 mb-6" slot="cta">
      <JumpToBuyBoxButton trackName="Say Goodybye CTA" />
    </div>
  </ProblemAnxiety>
  <HowItWorks />
  <UnderstandYourCar />
  <RealTimeHealthMonitoring>
    <div class="mt-2.5 w-full md:w-auto mb-6 md:mb-0" slot="cta">
      <JumpToBuyBoxButton trackName="Gif CTA" />
    </div>
  </RealTimeHealthMonitoring>

  <MoreReasons />

  <PeopleLove>
    <div class="mt-2.5 mb-4" slot="cta">
      <JumpToBuyBoxButton trackName="Review CTA" />
    </div>
  </PeopleLove>

  <StartUsing />
  <MobileApp />
  <About />
  <Satisfaction />
  <Compatibility />
  <div class="xs:hidden">
    <PricePoints />
  </div>
  <PaymentMethods />

  <FAQ />
  <Footer />
  <div class="fixed left-0 bottom-4 w-full xs:hidden">
    <div class="w-full px-4">
      <button class="rounded-button" on:click={scrollToBuyBox}>
        <span class="text-15">Claim Offer</span>
      </button>
    </div>
  </div>
</main>
